.sezexplorer {
    position: relative;
}

.zoneSelector {
    position: fixed;
    top: 10rem;
    left: 10px;
    background: #ffffff;
    padding: 0.8rem;
    font-size: 0.85rem;
    z-index: 1;
    border-radius: 4px;
    filter: drop-shadow(6px 6px 8px rgba(84, 83, 85, 0.5));
}

.react-select {
    min-width: 25rem;
    font-size: 1rem;
}

.zoneSelector .form {
    min-width: 25rem;
    position: relative;
    margin: 0.5em 1rem;
}

.zone-table {
    margin-bottom: 1rem;
}

.popup {
    font-size: 0.9rem;
    user-select: text;
    cursor: text;
    max-width: 40rem;
    filter: drop-shadow(6px 8px 8px rgba(84, 83, 85, 0.6));
}

.popup tr {
    /* border-bottom: 1px solid rgb(236, 236, 236); */
    border-top: 6px solid rgb(255, 255, 255);
}

.popup tr::before {
    border-bottom: 1px solid rgb(236, 236, 236);
}

.popup .key {
    color: #5c5c5c;
    display: inline-block;
    width: max-content;
}

.graph-link {
    text-decoration: underline;
    cursor: pointer;
}

.popup .value {
    /* font-style: italic; */
    color: rgb(48, 48, 48);
    /* font-weight: 600; */
    text-align: right;
    padding-left: 2rem;
}

.popup .value>span {
    display: inline-flex;
}

.popup a {
    text-decoration: underline;
    color: rgb(48, 48, 48);
    margin-top: 0.5rem;
}

.popup .limit-text {
    display: block;
    width: 21rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mapboxgl-ctrl {
    position: absolute;
    top: -40px;
    right: 0;
    z-index: 1;
}

.model-close {
    position: absolute;
    top: -1.2rem;
    right: -1.2rem;
}

@media(max-width: 1024px) {
    .zoneSelector {
        top: 5rem;
    }
}