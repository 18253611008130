.root {
    display: flex;
    align-items: flex-start
}

.arrow {
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 100%;
    filter: drop-shadow(4px 4px 8px rgba(84, 83, 85, 0.5));
    color: #545355;
}

.text {
    font-size: 1rem;
    margin-left: 1rem;
    text-align: justify;
}