.illicitActivity {
    position: relative;
}

.headContainer {
    position: inherit;
    background-color: #545355;
}

.headBg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(84, 83, 85, 0) 30%, #545355 100%);
}

.downButton {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, 50%);
}

.container {
    padding: 0 15rem !important;
}

.anchor {
    position: relative;
    padding-top: 3rem;
}

.caption {
    background: rgba(255, 255, 255, 0.86);
    border-radius: 4px;
    padding: 1rem;
}

.tip {
    font-size: 0.95rem;
    font-style: italic;
    font-weight: 700;
    line-height: 150%;
    color: #545355;
    text-align: justify;
}

.title {
    font-size: 2.25rem;
    font-weight: 700;
    text-transform: capitalize;
    color: #545355;
    line-height: 150%;
}

.text {
    font-size: 1rem;
    color: #545355;
    line-height: 150%;
    text-align: justify;
}

.descTitle {
    font-size: 1.5rem;
    line-height: 150%;
    font-weight: 400;
    color: #545355;
    text-align: justify;
}

.arrowButton {
    display: flex;
    align-items: center;
}

.arrowButton .label {
    font-size: 1.6rem;
    line-height: 150%;
    font-weight: 400;
    color: #545355;
    margin-left: 1.2rem;
}

.corruptionImg {
    width: 600px;
    position: absolute;
    transform: translateX(-50%);
    left: 20%;
    top: -15rem;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.corruptionImg img {
    width: 100%;
}

.mapTip {
    width: 420px;
    position: absolute;
    left: translateX(calc(50vw - 50%));
    font-size: 1.125rem;
    line-height: 160%;
    padding: 1.2rem;
    background: rgba(255, 255, 255, 0.88);
    text-align: center;
    color: #545355;
    font-style: normal;
    font-weight: bold;
}

.sourceTip {
    position: absolute;
    bottom: 2em;
    padding: 0rem;
    display: flex;
    width: 420px;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 160%;
    color: #545355;
    z-index: 5;
}

.corruptionTip {
    bottom: 6em;
}

.sourceTip a {
    color: #545355;
    text-decoration: underline;
    margin-left: 0.6em;
}

.ml {
    margin-left: 23rem;
}

.fc {
    display: flex;
    justify-content: center;
}

/* screen xl */

@media(max-width: 1280px) {
    .container {
        padding: 0 10rem !important;
    }
    .ml {
        margin-left: 29rem;
    }
}

/* screen lg */

@media(max-width: 1024px) {
    .container {
        padding: 0 5rem !important;
    }
    .corruptionImg {
        width: 80%;
        left: 40%;
        top: 0;
        position: relative;
    }
    .ml {
        margin-left: 0;
    }
}

/* screen md */

@media(max-width: 768px) {
    .container {
        padding: 0 3rem !important;
    }
}

/* screen sm */

@media(max-width: 640px) {
    .container {
        padding: 0 1rem !important;
    }
}