.imgWraper {
    position: relative;
    display: flex;
}

.imgHeader {
    display: flex;
}

.image {
    width: 100%;
}

.imgHeader .imgTitle {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 160%;
    color: #545355;
}

/* screen xl */

@media(max-width: 1280px) {}

/* screen lg */

@media(max-width: 1024px) {}

/* screen md */

@media(max-width: 768px) {}

/* screen sm */

@media(max-width: 640px) {}