.networkmapNav {
    display: flex;
    color: #545355;
    width: 100%;
}

.tips{
    flex-grow: 2;
}

.tip1 {
    /* margin-top: 0.5rem; */
    font-weight: 700;
    font-size: 1.1rem;
}

.tip2 {
    margin-top: 1rem;
    font-size: 0.8rem;
}

.space{
    flex-grow: 1;
}