.container {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
    padding: 0 15rem !important;
}

.title {
    font-size: 4.26rem;
    color: rgb(255, 255, 255);
    font-weight: bold;
    line-height: 160%;
    text-transform: uppercase;
}

.subTitle {
    font-size: 1.6rem;
    line-height: 200%;
    color: rgb(255, 255, 255);
}

.logo{
    margin-left: 0.7rem;
    padding-bottom: 1rem;
}

/* screen xl */

@media(max-width: 1280px) {
    .container {
        padding: 0 10rem !important;
    }
}

/* screen lg */

@media(max-width: 1024px) {
    .container {
        padding: 0 5rem !important;
    }
    .title {
        font-size: 2.5rem;
        line-height: 0%;
    }
    .subTitle {
        font-size: 1rem;
        font-weight: normal;
    }
}

/* screen md */

@media(max-width: 768px) {
    .container {
        padding: 0 3rem !important;
    }
}

/* screen sm */

@media(max-width: 640px) {
    .container {
        padding: 0 1rem !important;
    }
}