.select {
    width: 240px;
}

.dropDown {
    max-height: 500px;
    overflow: auto;
    z-index: 999999;
    box-shadow: none;
    padding: 8px 3px;
    border: 0.5px solid #CFCFD0;
    box-sizing: border-box;
    border-radius: 4px;
}

.node {
    display: flex;
    align-items: center;
}

.node .icon {
    margin-right: 0.4rem;
}

.node .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #545355;
}