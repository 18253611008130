.geopolitics {
    position: relative;
}

.headContainer {
    position: inherit;
    background-color: #545355;
}

.headBg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(84, 83, 85, 0) 30%, #545355 100%);
}

.downButton {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, 50%);
}

.container {
    padding: 0 15rem !important;
}

.anchor {
    position: relative;
    padding-top: 3rem;
}

.title {
    font-size: 2.25rem;
    font-weight: 700;
    text-transform: capitalize;
    color: #545355;
    line-height: 150%;
}

.text {
    font-size: 1rem;
    color: #545355;
    line-height: 150%;
    text-align: justify;
}

.descTitle {
    font-size: 1.5rem;
    line-height: 150%;
    font-weight: 400;
    color: #545355;
    text-align: justify;
}

.arrowButton {
    display: flex;
    align-items: center;
}

.arrowButton .label {
    font-size: 1.6rem;
    line-height: 150%;
    font-weight: 400;
    color: #545355;
    margin-left: 1.2rem;
}

.governmentBg {
    position: absolute;
    top: calc(50% + 3em);
    left: 50%;
    transform: translate(-50%, -50%);
}

.deepDriveCardRoot {
    position: absolute;
    right: 0rem;
    transform: translate(50%, -8em);
}

.deepDriveCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 18.8rem;
    background: #FFFFFF;
    box-shadow: 6px 6px 16px rgba(84, 83, 85, 0.5);
    border-radius: 4px;
}

.deepDriveCard .avatar {
    width: 100%;
}

.deepDriveCard .caption {
    margin-top: 1rem;
    font-size: 1.2rem;
    line-height: 1.45rem;
    color: #545355;
}

.deepDriveCard .caption a {
    color: #545355 !important;
    font-weight: 700 !important;
    text-decoration: unset !important;
}

.macCaptionRoot {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    transform: translate(20%, -50%);
}

.macCaption {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1rem;
    width: 17.4rem;
    background: rgba(255, 255, 255, 0.96);
    border-radius: 4px;
}

/* screen xl */

@media(max-width: 1280px) {
    .container {
        padding: 0 10rem !important;
    }
}

/* screen lg */

@media(max-width: 1024px) {
    .container {
        padding: 0 5rem !important;
    }
    .macCaptionRoot {
        position: relative;
        top: 0;
        right: 0;
        z-index: 1;
        transform: translate(0, 0);
    }
    .macCaption {
        width: 100%;
    }
}

/* screen md */

@media(max-width: 768px) {
    .container {
        padding: 0 3rem !important;
    }
}

/* screen sm */

@media(max-width: 640px) {
    .container {
        padding: 0 1rem !important;
    }
}