.flex {
    display: flex;
}

.overflow-visible {
    overflow: visible;
}

.overflow-hidden {
    overflow: hidden;
}