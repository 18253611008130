.nav {
    display: flex;
    margin: 1.5rem 1rem 0 1rem;
    font-size: 1rem;
}

.logo {
    margin-right: 1rem;
}

.logo img {
    width: 12rem;
}

.nav .gap {
    flex-grow: 1;
}

.navbar {
    flex-grow: 2;
}

.navbar .main {
    display: flex;
}

.navbar .sub {
    display: flex;
    justify-content: right;
    margin: 1em 0;
}

.link {
    flex-grow: 1;
    font-size: 1.5rem;
    font-weight: 400;
    color: #545355;
    text-transform: capitalize;
    cursor: pointer;
    transition: all .25s ease-out;
    text-decoration: none;
    width: max-content;
    padding: 0.7rem 2.5rem 0.7rem 0;
}

.link:hover {
    color: #545355;
}

.linkActive {
    font-weight: 700;
}

.menu {
    display: none;
}

/* screen xl */

@media(max-width: 1440px) {}

/* screen lg */

@media(max-width: 1024px) {
    .nav {
        font-size: 0.8rem;
        margin: 0.5rem 1rem;
    }
    .logo img {
        width: 8rem;
    }
    .navbar {
        display: flex;
        justify-content: flex-end;
    }
    .navbar .sub {
        display: none
    }
    .navbar .main {
        display: none;
    }
    .menu {
        display: block
    }
}

/* screen md */

@media(max-width: 768px) {}

/* screen sm */

@media(max-width: 640px) {}