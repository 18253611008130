.ant-select-clear {
    display: none !important;
}

.ant-select:hover {
    border-color: black;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.5px solid #CFCFD0;
    box-sizing: border-box;
    border-radius: 4px;
}

.ant-select-arrow {
    right: 15px;
    top: 58%;
}