.graphContainer {
  position: relative;
  height: calc(100vh - 12em);
  width: 100%;
  background: #eee;
}

.graph {
  height: 100%;
  width: 100%;
  background: #eee;
}

.logo {
  position: absolute;
  bottom: 1rem;
  left: auto;
  right: 1rem;
  z-index: 1;
}

.logo>img {
  width: 9rem;
}