.home {
    position: relative;
}

.headContainer {
    position: inherit;
    background-color: #545355;
}

.headBg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(84, 83, 85, 0) 0%, #2b2b2b 100%);
}

.downButton {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, 50%);
}

.anchor {
    position: relative;
    padding-top: 3rem;
}

.container {
    padding: 0 15rem !important;
}

.title {
    font-size: 2.25rem;
    font-weight: 700;
    text-transform: capitalize;
    color: #545355;
    line-height: 150%;
    text-align: justify;
}

.text {
    font-size: 1rem;
    color: #545355;
    line-height: 150%;
    text-align: justify;
}

/* screen xl */

@media(max-width: 1280px) {
    .container {
        padding: 0 10rem !important;
    }
}

/* screen lg */

@media(max-width: 1024px) {
    .container {
        padding: 0 5rem !important;
    }
}

/* screen md */

@media(max-width: 768px) {
    .container {
        padding: 0 3rem !important;
    }
}

/* screen sm */

@media(max-width: 640px) {
    .container {
        padding: 0 1rem !important;
    }
}