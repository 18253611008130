.graphicContainer {
  display: flex;
  position: relative;
}

.graphic {
  position: sticky;
  top: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  transform: translateX(-60%);
  margin: 0;
  padding: 0;
}

.graphic .graphicRoot {
  height: inherit;
  position: relative;
  margin: 0;
  padding: 0;
}

.graphic .graphicRoot img {
  height: inherit;
  margin: 0;
  padding: 0;
  transition: all 0.5s ease;
}

.scroller {
  z-index: 1;
  position: relative;
  min-width: 50em;
}

.scroller>div:first-child {
  display: none;
}

.step {
  height: 100vh;
}

.targetStep {
  height: 60vh;
}

.title {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: capitalize;
  color: #545355;
  line-height: 150%;
}

.text {
  font-size: 1rem;
  color: #545355;
  line-height: 150%;
  text-align: justify;
}

.caption {
  padding: 0.2rem 1.2rem;
  background: rgba(255, 255, 255, 0.88) !important;
}

.extendTip {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 160%;
  text-align: justify;
  color: #545355;
  position: relative;
}

.extendTip::before {
  content: '';
  position: absolute;
  top: 0rem;
  left: -1.2rem;
  width: 0.26rem;
  height: 100%;
  background-color: #545355;
  border-radius: 4px;
}

.imageTip {
  width: fit-content;
  background: rgba(255, 255, 255, 0.88);
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 160%;
  color: #545355;
  padding: 0.5rem;
}

.overlap {
  position: absolute;
  top: 0;
  left: 0;
}

/* screen xl */

@media(max-width: 1440px) {
  .scroller {
    min-width: 40em;
  }
}

/* screen lg */

@media(max-width: 1024px) {
  .scroller {
    min-width: 30em;
  }
  .targetStep {
    height: 160vh;
  }
  .graphic {
    top: 5rem;
    width: min-content;
    height: 70vh;
    transform: translateX(-60%);
  }
}

/* screen md */

@media(max-width: 768px) {}

/* screen sm */

@media(max-width: 640px) {
  .scroller {
    min-width: 25em;
  }
}