.container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 2;
    padding: 0 15rem !important;
}

.title {
    text-transform: uppercase;
    font-size: 2.66rem;
    font-weight: 700;
    line-height: 150%;
    text-align: justify;
    color: rgb(240, 240, 240);
}

.subTitle {
    font-size: 1.66rem;
    font-weight: 700;
    line-height: 150%;
    text-align: justify;
    color: rgb(240, 240, 240);
}

/* screen xl */

@media(max-width: 1280px) {
    .container {
        padding: 0 10rem !important;
    }
}

/* screen lg */

@media(max-width: 1024px) {
    .container {
        padding: 0 5rem !important;
    }
    .title {
        font-size: 1.8rem;
    }
    .subTitle {
        font-size: 1rem;
        font-weight: normal;
    }
}

/* screen md */

@media(max-width: 768px) {
    .container {
        padding: 0 3rem !important;
    }
}

/* screen sm */

@media(max-width: 640px) {
    .container {
        padding: 0 1rem !important;
    }
    .subTitle {
        font-size: 1rem;
    }
}