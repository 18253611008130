.methodology {
    position: relative;
}

.container {
    padding: 0 15rem !important;
}

.subTitle {
    font-size: 2rem;
    font-style: italic;
    font-weight: bold;
    color: #545355;
    line-height: 150%;
    text-align: justify;
}

.subTitle2 {
    font-size: 2rem;
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: bold;
    color: #545355;
    line-height: 150%;
    text-align: justify;
}

.text {
    font-size: 1rem;
    color: #545355;
    line-height: 150%;
    text-align: justify;
}

.formula {
    font-size: 1.1rem;
    color: #545355;
    line-height: 150%;
    text-align: center;
}

.logoListWrapper {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 83%;
    max-width: 1200px;
}

.logoList {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.logo {
    width: 12rem;
}

.logo img {
    width: 100%;
}

/* screen xl */

@media(max-width: 1280px) {
    .container {
        padding: 0 10rem !important;
    }
}

/* screen lg */

@media(max-width: 1024px) {
    .container {
        padding: 0 5rem !important;
    }
    .logoList {
        flex-direction: row;
    }
}

/* screen md */

@media(max-width: 768px) {
    .container {
        padding: 0 3rem !important;
    }
}

/* screen sm */

@media(max-width: 640px) {
    .container {
        padding: 0 1rem !important;
    }
}