.footer {
    color: #414042;
    padding: 2rem 0 0.8rem 0;
    background-color: white;
    position: absolute;
    z-index: 1;
}

.footer .logo-container {
    max-width: 500px;
    flex-grow: 1;
}

.footer .logo-container .logo {
    width: 8.625rem;
}

.footer .logo-container .logo>img {
    width: 100%;
}

.footer .logo-container .divider {
    height: 0.25rem;
    width: 13.8rem;
    background-color: #F75151;
    margin-bottom: 0.3rem;
}

.footer .logo-container span{
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.07rem;
}

.footer .list{
    flex-grow: 0.15;
}

.footer .list li {
    list-style: none;
}

.footer .list li:first-child {
    margin: 3.2rem 0 0.8rem 0;
}

.footer .list li a {
    color: #414042;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.07rem;
}