.networkmap {
    position: relative;
}

.vis-network {
    width: inherit !important;
    height: inherit !important;
}

.drawer-paper {
    top: 0;
    position: absolute !important;
}

.toggle-flag-wrapper{
    display: none;
    position: absolute;
    top: 5rem;
    right: 2rem;
}

.filter-wrapper{
    display: none;
    position: absolute;
    top: 5rem;
    left: 2rem;
}

@media(max-width: 1024px) {
    .toggle-flag-wrapper{
        display: block;
    }
    
    .filter-wrapper{
        display: block;
    }   
}