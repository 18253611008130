.casestudies {
    position: relative;
}

.casestudies .sideBar {
    width: 20rem;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: auto;
    z-index: 1;
    background-color: white;
    padding-top: 10rem;
    transition: all 0.5s ease;
}

.casestudies .sideBar::-webkit-scrollbar {
    width: 7px;
    height: 10px;
}

.casestudies .sideBar::-webkit-scrollbar-track {
    background-color: rgb(235, 235, 235);
    border-radius: 3px;
}

.casestudies .sideBar::-webkit-scrollbar-thumb {
    height: 92px;
    background-color: #dadada;
    border-radius: 3px;
}

.casestudies .sideBar .group-link {
    font-size: 1.125rem;
    padding: 0.0em 0 0.5em 1rem;
    line-height: 1.375rem;
    text-transform: capitalize;
}

.casestudies .sideBar .list-link {
    display: flex;
    flex-direction: column;
}

.casestudies .sideBar .link {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.7rem 0 0.7rem 3.5rem;
    cursor: pointer;
    line-height: 1rem;
    color: #545355;
}

.casestudies .sideBar .link-active {
    font-weight: 700;
    background-color: #222222;
    color: white;
}

.casestudies .sideBar .link::after {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
    width: 30px;
    height: 60px;
    border-right: solid 30px rgb(200, 30, 50);
    border-bottom: solid 30px transparent;
    border-top: solid 30px transparent;
}

.casestudies .sideBar .tip {
    font-size: 0.9rem;
    padding: 0 0 0.5rem 1rem;
    line-height: 1.375rem;
    display: inline-flex;
    align-items: center;
}

.casestudies .sideBar .tip img {
    margin-right: 0.5rem;
    width: 1.6rem;
}

.casestudies .content {
    min-height: 100vh;
}

.casestudies .content .overlap-content {
    position: relative;
    min-height: 100vh;
    border-left: 20rem solid rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.85);
    /* backdrop-filter: blur(2px); */
}

.casestudies .content .map-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
}

.casestudies .content .map-container-lock {
    position: fixed !important;
    z-index: -1 !important;
    top: 10rem;
}

.tooltip {
    font-size: 0.9rem;
}

.tooltip .key {
    color: #313131;
    display: inline-block;
    width: max-content;
}

.tooltip .value {
    color: rgb(0, 0, 0);
    text-align: right;
}

.arrowButton {
    display: flex;
    align-items: center;
}

.arrowButton .label {
    font-size: 1.6rem;
    line-height: 150%;
    font-weight: 400;
    color: #545355;
    margin-left: 1.2rem;
}

.ctrlContainer {
    padding: 0 15rem 0 7rem;
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}

/* screen xl */

@media(max-width: 1280px) {
    .ctrlContainer {
        padding: 0 10rem 0 5rem;
    }
}

/* screen lg */

@media(max-width: 1024px) {
    .ctrlContainer {
        padding: 0 3rem;
    }
    .casestudies .sideBar {
        display: none;
    }
    .casestudies .content .overlap-content {
        border-left: none;
    }
}

/* screen md */

@media(max-width: 768px) {}

/* screen sm */

@media(max-width: 640px) {}

@media(max-height: 1080px) {}

@media(max-height: 900px) {}

@media(max-height: 800px) {}

@media(max-height: 600px) {}