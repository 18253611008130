.nodeInfo {
    display: flex;
    flex-direction: column;
    width: 31rem;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1.5rem;
}

.nodeInfo::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.nodeInfo::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
}

.nodeInfo::-webkit-scrollbar-thumb {
    height: 92px;
    background-color: #bebebe;
    border-radius: 8px;
}

.head {
    display: flex;
    position: relative;
}

.head img {
    width: 5.8rem;
    height: 5.8rem;
    margin-right: 0.8rem;
    border-radius: 360px;
    /* background-color: rgb(53, 53, 53); */
}

.closeButton {
    position: absolute;
    right: 0;
    top: 0;
}

.labelCountry {
    width: 18rem;
}

.label {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 160%;
    color: #545355;
}

.country {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 400;
}

.detailHeader {
    text-transform: capitalize;
    background-color: #545355;
    color: white;
    margin: 1em 0em 1em 0;
    padding-left: 0.9rem;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 2px;
    height: 1.5rem;
    display: flex;
    align-items: center;
}

.detailTable {
    font-size: 0.875rem;
    color: #545355;
    margin: 0 1rem;
    width: fit-content;
}

.tableKey {
    display: block;
    font-weight: 700;
    width: max-content;
    padding-bottom: 1rem;
    padding-right: 0.5rem;
}

.tableKey>div {
    width: 10em;
}

.tableValue {
    flex-wrap: wrap;
    font-weight: 400;
    padding-bottom: 1rem;
    text-align: justify;
}

.tableValue>div {
    width: 19em;
}