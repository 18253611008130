.graphicContainer {
  display: flex;
  /* justify-content: space-between; */
}

.graphic {
  position: sticky;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graphic>img {
  width: inherit;
  max-height: 100%;
}

.scroller {
  z-index: 1;
  transform: translateX(calc(50vw - 50%));
}

.scroller>div:first-child {
  display: none;
}

.step {
  width: 58vw;
  min-width: 400px;
  height: 70vh;
}

.title {
  font-size: 2.25rem;
  font-weight: 700;
  text-transform: capitalize;
  color: #545355;
  line-height: 150%;
}

.text {
  font-size: 1rem;
  color: #545355;
  line-height: 150%;
  text-align: justify;
}

.caption{
  padding: 0.2rem 1.2rem;  
}

/* screen xl */

@media(max-width: 1440px) {
  .graphic>img {
    height: inherit;
    width: auto;
  }
}

/* screen lg */

@media(max-width: 1024px) {}

/* screen md */

@media(max-width: 768px) {}

/* screen sm */

@media(max-width: 640px) {}