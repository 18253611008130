.ml {
    margin-left: 2rem;
}

.mr {
    margin-right: 2rem;
}

.flexDirCol {
    flex-direction: column;
}

.bookContainer {
    margin-left: 5rem;
    margin-right: 25rem;
}

.title {
    font-size: 3.2rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #545355;
    line-height: 160%;
}

.subTitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: #545355;
    line-height: 150%;
}

.textDesc {
    font-size: 1rem;
    font-weight: 400;
    color: #545355;
    line-height: 1.7rem;
    text-align: justify;
    font-style: italic;
}

.text {
    font-size: 1rem;
    font-weight: 400;
    color: #545355;
    line-height: 1.7rem;
    text-align: justify;
}

.comment {
    font-size: 1rem;
    font-weight: 700;
    color: #545355;
    line-height: 1.7rem;
    text-align: justify;
}

.imgWraper {
    position: relative;
    display: flex;
}

.imgWraper .imgSliderWraper {
    display: flex;
    justify-content: center;
}

.imgWraper .imageCompareCaption {
    top: 50%;
}

.imgWraper .imgCompLeft {
    position: absolute;
    top: 50%;
    left: -1.2rem;
    transform: translate(-50%, -50%) rotate(-90deg);
    font-family: inherit;
    font-size: 1.1rem;
}

.imgWraper .imgCompRight {
    position: absolute;
    top: 50%;
    right: -3.5rem;
    transform: translate(-50%, -50%) rotate(-90deg);
    font-family: inherit;
    font-size: 1.1rem;
}

.imgWraper .mapCaption {
    position: absolute;
    bottom: 20%;
    right: -5rem;
    padding: 1rem;
    font-style: italic;
    font-size: 0.9rem;
    line-height: 160%;
    text-align: justify;
    color: #545355;
    background: #FFFFFF;
    box-shadow: 6px 6px 16px rgba(84, 83, 85, 0.5);
    border-radius: 4px;
    width: 20rem;
    height: fit-content;
    z-index: 1;
}

.imgWraper .imageTip {
    position: absolute;
    width: 60%;
    bottom: 0.5rem;
    right: 1rem;
    background: rgba(255, 255, 255, 0.88);
    border-radius: 4px;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 160%;
    color: #545355;
    padding: 0.5rem 1rem;
}

.image {
    width: 100%;
}

.anchor {
    padding-top: 2rem;
}

.imgSlider {
    min-width: 255px;
    --divider-width: 2px;
    --divider-color: #00e7b5;
    --handle-opacity: 1;
    --handle-opacity-active: 0.4;
    --handle-size: 40px;
    --handle-color: #00e7b5;
}

.imgHeader {
    display: flex;
}

.imgHeader .imgNum {
    font-style: italic;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 160%;
    color: #545355;
    margin-right: 1.5rem;
    width: fit-content;
}

.imgHeader .imgTitle {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 160%;
    color: #545355;
}

.imgFooter {
    font-size: 0.95rem;
    line-height: 160%;
    text-align: justify;
    color: #545355;
}

.imgSpace {
    position: relative;
}

.imgSpace>span {
    font-size: 1.2rem;
    line-height: 160%;
    color: #545355;
}

.imgText {
    position: absolute;
    top: 25%;
    right: 1.1rem;
    width: max-content;
    transform: translate(50%, -50%) rotateZ(-90deg);
}

.imgText2{
    top: 75%;
}

.imgText>span {
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 160%;
    color: #545355;
}

.caption {
    position: absolute;
    top: 60%;
    right: 0;
    z-index: 1;
    transform: translate(50%, -50%);
    width: 20rem;
    font-size: 0.9rem;
    line-height: 160%;
    text-align: justify;
    color: #545355;
    background: rgba(255, 255, 255, 0.86);
    border-radius: 4px;
    padding: 1rem;
}

.imgWithCaption {
    margin-right: 10rem;
}

.zoneItem {
    display: flex;
    align-items: center;
}

.zoneItem>span {
    min-width: 10rem;
    font-size: 1.2rem;
    line-height: 160%;
    text-align: justify;
    color: #545355;
    margin-left: 1.6rem;
}

.extendTip {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 160%;
    text-align: justify;
    color: #545355;
    position: relative;
    margin-left: 1.2rem;
}

.extendTip::before {
    content: '';
    position: absolute;
    top: 0rem;
    left: -1rem;
    width: 0.26rem;
    height: 100%;
    background-color: #545355;
    border-radius: 4px;
}

.paper {
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 6px 6px 16px rgba(84, 83, 85, 0.5);
    border-radius: 4px;
    padding: 1.5rem;
}

/* screen xl */

@media(max-width: 1280px) {
    .bookContainer {
        margin-left: 5rem;
        margin-right: 15rem;
    }
}

/* screen lg */

@media(max-width: 1024px) {
    .bookContainer {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .caption {
        position: relative;
        transform: unset;
        width: inherit;
    }
    .imgWithCaption {
        margin-right: 0;
    }
    .imgWraper {
        flex-direction: column;
    }
    .imgWraper .mapCaption {
        right: 0rem;
    }
}

/* screen md */

@media(max-width: 768px) {
    .bookContainer {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .title {
        font-size: 2rem;
    }
}

/* screen sm */

@media(max-width: 640px) {
    .bookContainer {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}