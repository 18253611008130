.link {
    font-size: 1.125rem;
    text-transform: capitalize;
    padding: 0.7rem 1.5rem 0.7rem 0;
    cursor: pointer;
    color: #545355;
    width: max-content;
}

.link:hover{
    color: #545355;
}

.linkActive {
    font-weight: 700;
}

.downloadModal {
    position: relative;
    margin-top: 5rem;
}