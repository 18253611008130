@font-face {
  font-family: 'century_gothic';
  src: url('./assets/fonts/century_gothic.eot');
  src: local('century_gothic'), url('./assets/fonts/century_gothic.woff') format('woff'), url('./assets/fonts/century_gothic.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'century_gothic' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  line-height: 150%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
}

/* body::-webkit-scrollbar {
  width: 17px;
  height: 10px;
}
body::-webkit-scrollbar-track {
  background-color: rgb(235, 235, 235);
  border-radius: 8px;
}
body::-webkit-scrollbar-thumb {
  height: 92px;
  background-color: #c5c5c5 ;
  border-radius: 8px;
} */

.deck-tooltip {
  background: #FFFFFF !important;
  box-shadow: 2px 2px 3px rgba(84, 83, 85, 0.8);
  border-radius: 4px;
}

.p-tooltip {
  max-width: 20rem !important;
  z-index: 99999;
}

.p-tooltip .p-tooltip-arrow {
  border-top-color: white !important;
  border-bottom-color: white !important
}

.p-tooltip .p-tooltip-text {
  font-family: "century_gothic" !important;
  background: white !important;
  word-break: break-word;
  line-height: 1.4rem;
  color: #545355;
  box-shadow: 2px 2px 4px rgba(84, 83, 85, 0.4) !important;
  text-align: justify;
  padding: 0.6em 1rem;
  font-size: 0.9rem;
}

::selection {
  color: #fff;
  background: #2b2b2b;
}

*, :after, :before {
  box-sizing: border-box;
}

/* screen xl */

@media(max-width: 1280px) {}

/* screen lg */

@media(max-width: 1024px) {
  body {
    font-size: 14px;
  }
}

/* screen md */

@media(max-width: 768px) {}

/* screen sm */

@media(max-width: 640px) {}

@media(max-height: 1080px) {}

@media(max-height: 900px) {}

@media(max-height: 800px) {
  body {
    font-size: 13px;
  }
}

@media(max-height: 600px) {}