.imgWraper {
    position: relative;
    display: flex;
    flex-direction: column;
}

.captionWraper {
    position: absolute;
    top: 7em;
    right: -5em;
    z-index: 1;
}

.caption {
    width: 25rem;
    font-size: 0.9rem;
    line-height: 160%;
    text-align: justify;
    color: #545355;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
}

.imgHeader {
    display: flex;
}

.image {
    width: 100%;
}

.imgHeader .imgTitle {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 160%;
    color: #545355;
}

/* screen xl */

@media(max-width: 1280px) {}

/* screen lg */

@media(max-width: 1024px) {
    .captionWraper {
        position: relative;
        top: 0;
        right: 0;
        z-index: 1;
    }
    .caption {
        width: 100%;
    }
}

/* screen md */

@media(max-width: 768px) {}

/* screen sm */

@media(max-width: 640px) {}